// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

declare const window: any;

export const environment = {
  name: 'development',
  production: false,
  //apiUrl: "http://localhost:8097",
  //apiUrl: "https://sls-rh-api-dev.algosft.ma",
  //apiUrl: "https://sls-rh-api-staging.algosft.ma",
  apiUrl: window["env"]["apiUrl"] || "https://sls-rh-api-dev.algosft.ma",
  debug: window["env"]["debug"] || false
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
