import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestDto } from 'src/app/core/dtos/request.dto';
import { File } from 'src/app/core/models/file.model';
import { AuthService } from 'src/app/core/services/auth.service';
import { FileService } from 'src/app/core/services/file.service';
import { RequestService } from 'src/app/core/services/request.service';
import { AlertService } from 'src/app/core/services/alert.service'
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MANAGE_ALL_REQUESTS, MANAGE_SUB_REQUESTS } from 'src/app/core/constants/privilege';

@Component({
  selector: 'app-request-modal',
  templateUrl: './request-modal.component.html',
  styleUrls: ['./request-modal.component.scss']
})
export class RequestModalComponent {

  requestForm!: FormGroup;
  selectedFile?: File;
  isFormDisabled: boolean = true;
  canManageRequests: boolean = false;

  constructor(public requestService: RequestService, private fileService: FileService,
    public authService: AuthService, private alertService: AlertService, private dialogRef: MatDialogRef<RequestModalComponent>,
    @Inject(MAT_DIALOG_DATA) public request: RequestDto | undefined) {
    this.canManageRequests = authService.hasAnyPrivilege([MANAGE_SUB_REQUESTS, MANAGE_ALL_REQUESTS]);
    this.initRequestForm();
  }

  ngOnInit(): void {
  }



  initRequestForm = async () => {
    if (this.request) {
      // let category = await this.categoryList.find(c => c.name === this.request?.category);
      // let priority = await this.priorityList.find(p => p.name === this.request?.priority);
      // let status = await this.statusList.find(s => s.name === this.request?.status);
      const isHoliday: boolean = this.request?.category == 'HOLIDAY';
      this.requestForm = new FormGroup({
        id: new FormControl(this.request.id, [Validators.required]),
        category: new FormControl(this.request?.category, [Validators.required]),
        startDate: new FormControl(this.request.startDate, isHoliday ? [Validators.required] : []),
        endDate: new FormControl(this.request.endDate, isHoliday ? [Validators.required] : []),
        priority: new FormControl(this.request?.priority, []),
        title: new FormControl(this.request.title, [Validators.required]),
        description: new FormControl(this.request.description, [Validators.required]),
        status: new FormControl(this.request?.status, [Validators.required]),
      });
    } else {
      this.requestForm = new FormGroup({
        category: new FormControl('HOLIDAY', [Validators.required]),
        startDate: new FormControl(null, []),
        endDate: new FormControl(null, []),
        priority: new FormControl('LOW', []),
        title: new FormControl('', [Validators.required]),
        description: new FormControl('', [Validators.required]),
        status: new FormControl('PENDING', [Validators.required]),
      });
    }

    // Watch for changes and add/remove "required" validator as needed
    this.requestForm.valueChanges.subscribe(() => {
      const isHoliday: boolean = (this.requestForm.value.category.name == 'HOLIDAY' || this.requestForm.value.category.name == 'ILLNESS_HOLIDAY');
      const startDateControl = this.requestForm.get('startDate');
      const endDateControl = this.requestForm.get('endDate');
      if (isHoliday) {
        startDateControl?.setValidators([Validators.required]);
        endDateControl?.setValidators([Validators.required]);
      } else {
        startDateControl?.clearValidators();
        endDateControl?.clearValidators();
      }
    });

    this.checkIsFormDisabled();
  }

  onSaveRequest = async () => {
    if (!this.requestForm.valid) {
      return;
    }

    if (this.request?.id == null) {
      this.request = new RequestDto();
    }

    this.request!.category = this.requestForm.value.category;
    this.request!.startDate = this.requestForm.value.startDate;
    this.request!.endDate = this.requestForm.value.endDate;
    this.request!.priority = this.requestForm.value.priority;
    this.request!.title = this.requestForm.value.title;
    this.request!.description = this.requestForm.value.description;
    this.request!.status = this.requestForm.value.status;

    if (this.selectedFile?.attachment) {
      this.request!.attachment = this.selectedFile?.attachment;
    }

    if (this.requestForm.value.id) {
      if(this.canManageRequests) {
        await this.manageRequest(this.request);
      } else {
        await this.updateRequest(this.request);
      } 
    } else {
      await this.saveRequest(this.request);
    }
  }

  async saveRequest(request: RequestDto) {
    await this.requestService.saveRequest(request)
      .then(data => {
        this.closeDialog();
        this.requestService.retrieveRequestPage();

        // Show success alert
        this.alertService.showAlert('success', 'Succès!', 'Demande enregistrée avec succès.');
      }).catch(error => {
        console.log(error);
        // Show error alert
        this.alertService.showAlert('error', 'Erreur!', 'Une erreur s\'est produite lors de l\'enregistrement de la demande.');
      });
  }

  async updateRequest(request: RequestDto) {
    await this.requestService.updateRequest(request)
      .then((data) => {
        this.closeDialog();
        this.requestService.retrieveRequestPage();

        // Show success alert
        this.alertService.showAlert('success', 'Succès!', 'La demande été mise à jour avec succès.');
      }).catch((error) => {
        console.log(error);
        // Show error alert
        this.alertService.showAlert('error', 'Erreur!', 'Une erreur s\'est produite lors de modifaction de la demande.');
      });
  }

  async manageRequest(request: RequestDto) {
    await this.requestService.manageRequest(request)
      .then((data) => {
        this.closeDialog();
        this.requestService.retrieveRequestPage();

        // Show success alert
        this.alertService.showAlert('success', 'Succès!', 'La demande été mise à jour avec succès.');
      }).catch((error) => {
        console.log(error);
        // Show error alert
        this.alertService.showAlert('error', 'Erreur!', 'Une erreur s\'est produite lors de modifaction de la demande.');
      });
  }

  async onFileSelected(event: any) {
    this.fileService.onFileSelected(event, 'REQUEST')
      .then(file => {
        if (file) this.selectedFile = file;
      }).catch(error => {
        console.log(error);
      });
  }

  /**
  * On DatePicker input emit an event 
  * @param date 
  */
  onDatePickerEmitEvent = (startOrEnd: string, date: Date) => {
    if (startOrEnd === 'start') {
      const startDateControl = this.requestForm.get('startDate');
      startDateControl?.setValue(date);
    } else if (startOrEnd === 'end') {
      const endDateControl = this.requestForm.get('endDate');
      endDateControl?.setValue(date);
    }
  }

  checkIsFormDisabled(): void {
    if (this.canManageRequests)
      this.isFormDisabled = (this.request != null && this.request!.status != 'PENDING' && this.request!.status != 'IN_PROGRESS');
    else this.isFormDisabled = (this.request != null && this.request!.status != 'PENDING');
  }

  closeDialog = async () => {
    this.dialogRef.close();
  }

}
