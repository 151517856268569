
// export const READ_ALL_PROFILES : string = "READ_ALL_PROFILES";
// export const READ_PROFILE: string = "READ_PROFILE";
// export const CREATE_PROFILE: string = "CREATE_PROFILE";
// export const EDIT_PROFILE: string = "EDIT_PROFILE";
// export const DELETE_PROFILE: string = "DELETE_PROFILE";
// export const READ_ALL_REQUESTS: string = "READ_ALL_REQUESTS";
// export const READ_REQUEST: string = "READ_REQUEST";
// export const CREATE_REQUEST: string = "CREATE_REQUEST";
// export const EDIT_REQUEST: string = "EDIT_REQUEST";
// export const DELETE_REQUEST: string = "DELETE_REQUEST";
// export const READ_ALL_ROLES: string = "READ_ALL_ROLES";
// export const READ_ROLE: string = "READ_ROLE";
// export const EDIT_ROLE: string = "EDIT_ROLE";
// export const DELETE_ROLE: string = "DELETE_ROLE";
// export const READ_EVALUATION: string = "READ_EVALUATION";
// export const EDIT_EVALUATION: string = "EDIT_EVALUATION";
// export const DELETE_EVALUATION: string = "DELETE_EVALUATION";
// export const READ_ALL_SERVICES: string = "READ_ALL_SERVICES";
// export const READ_SERVICE: string = "READ_SERVICE";
// export const EDIT_SERVICE: string = "EDIT_SERVICE";
// export const DELETE_SERVICE: string = "DELETE_SERVICE";
// export const READ_ALL_PROJECTS: string = "READ_ALL_PROJECTS";
// export const READ_PROJECT: string = "READ_PROJECT";
// export const EDIT_PROJECT: string = "EDIT_PROJECT";
// export const DELETE_PROJECT: string = "DELETE_PROJECT";
// export const MANAGE_REQUESTS: string = "MANAGE_REQUESTS";
// export const MANAGE_PARTICIPATIONS: string = "MANAGE_PARTICIPATIONS";
// export const READ_ALL_PARTICIPATIONS: string = "READ_ALL_PARTICIPATIONS";
// export const MANAGE_EVALUATIONS: string = "MANAGE_EVALUATIONS";
// export const READ_ALL_EVALUATIONS: string = "READ_ALL_EVALUATIONS";
// export const MANAGE_PROFILES: string = "MANAGE_PROFILES";



export const ACCESS_PARTICIPATIONS_PAGE: string = "ACCESS_PARTICIPATIONS_PAGE";
export const READ_PARTICIPATIONS: string = "READ_PARTICIPATIONS";
export const READ_SUB_PARTICIPATIONS: string = "READ_SUB_PARTICIPATIONS";
export const READ_ALL_PARTICIPATIONS: string = "READ_ALL_PARTICIPATIONS";
export const ADD_PARTICIPATION: string = "ADD_PARTICIPATION";
export const ADD_SUB_PARTICIPATIONS: string = "ADD_SUB_PARTICIPATIONS";
export const EDIT_PARTICIPATION: string = "EDIT_PARTICIPATION";
export const EDIT_SUB_PARTICIPATIONS: string = "EDIT_SUB_PARTICIPATIONS";
export const VALIDATE_SUB_PARTICIPATIONS: string = "VALIDATE_SUB_PARTICIPATIONS";
export const VALIDATE_ALL_PARTICIPATIONS: string = "VALIDATE_ALL_PARTICIPATIONS";

export const ACCESS_REQUESTS_PAGE: string = "ACCESS_REQUESTS_PAGE";
export const READ_REQUESTS: string = "READ_REQUESTS";
export const READ_SUB_REQUESTS: string = "READ_SUB_REQUESTS";
export const READ_ALL_REQUESTS: string = "READ_ALL_REQUESTS";
export const ADD_REQUEST: string = "ADD_REQUEST";
export const EDIT_REQUEST: string = "EDIT_REQUEST";
export const MANAGE_SUB_REQUESTS: string = "MANAGE_SUB_REQUESTS";
export const MANAGE_ALL_REQUESTS: string = "MANAGE_ALL_REQUESTS";

export const ACCESS_REVIEWS_PAGE: string = "ACCESS_REVIEWS_PAGE";
export const READ_REVIEWS: string = "READ_REVIEWS";
export const READ_SUB_REVIEWS: string = "READ_SUB_REVIEWS";
export const READ_ALL_REVIEWS: string = "READ_ALL_REVIEWS";
export const MANAGE_SUB_REVIEWS: string = "MANAGE_SUB_REVIEWS";
export const MANAGE_ALL_REVIEWS: string = "MANAGE_ALL_REVIEWS";

export const ACCESS_PROJECTS_PAGE: string = "ACCESS_PROJECTS_PAGE";
export const READ_AFFECTED_PROJECTS: string = "READ_AFFECTED_PROJECTS";
export const READ_ALL_PROJECTS: string = "READ_ALL_PROJECTS";
export const ADD_PROJECT: string = "ADD_PROJECT";
export const EDIT_PROJECT: string = "EDIT_PROJECT";
export const EDIT_AFFECTED_PROJECTS: string = "EDIT_AFFECTED_PROJECTS";
export const EDIT_ALL_PROJECTS: string = "EDIT_ALL_PROJECTS";
export const MANAGE_PROJECT_MEMBERS: string = "MANAGE_PROJECT_MEMBERS";

export const ACCESS_PROFILES_PAGE: string = "ACCESS_PROFILES_PAGE";
export const READ_PROFILE: string = "READ_PROFILE";
export const READ_SUB_PROFILES: string = "READ_SUB_PROFILES";
export const READ_ALL_PROFILES: string = "READ_ALL_PROFILES";
export const ADD_PROFILE: string = "ADD_PROFILE";
export const EDIT_PROFILE: string = "EDIT_PROFILE";
export const EDIT_SUB_PROFILES: string = "EDIT_SUB_PROFILES";
export const EDIT_ALL_PROFILES: string = "EDIT_ALL_PROFILES";

export const ACCESS_SERVICES_PAGE: string = "ACCESS_SERVICES_PAGE";
export const ADD_SERVICE: string = "ADD_SERVICE";
export const EDIT_SERVICE: string = "EDIT_SERVICE";
export const READ_ALL_SERVICES: string = "READ_ALL_SERVICES";

export const ACCESS_ROLES_PAGE: string = "ACCESS_ROLES_PAGE";
export const ADD_ROLE: string = "ADD_ROLE";
export const EDIT_ROLE: string = "EDIT_ROLE";
export const READ_ALL_ROLES: string = "READ_ALL_ROLES";

export const ACCESS_DASHBOARD_PAGE: string = "ACCESS_DASHBOARD_PAGE";
export const ACCESS_NOTIFICATIONS_PAGE: string = "ACCESS_NOTIFICATIONS_PAGE";
export const ACCESS_SETTINGS_PAGE: string = "ACCESS_SETTINGS_PAGE";


// export class Privilege {
// }