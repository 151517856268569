import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable, catchError, finalize, throwError } from 'rxjs';
import { LoaderService } from '../services/loader.service';

@Injectable()
export class LoadingInterceptor implements HttpInterceptor {


  constructor(private loaderService: LoaderService) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (request.url.includes('/v1.0/attachment/getFile') || request.url.includes('/assets')) {
      return next.handle(request);
    }

    this.loaderService.totalRequests++;
    this.loaderService.setLoading(true);

    return next.handle(request).pipe(
      catchError((error) => {
        this.loaderService.totalRequests--; // Ensure decrement on error
        if (this.loaderService.totalRequests === 0) {
          this.loaderService.setLoading(false);
        }
        return throwError(() => error);
      }),
      finalize(() => {
        this.loaderService.totalRequests--;
        if (this.loaderService.totalRequests === 0) {
          this.loaderService.setLoading(false);
        }
      })
    );
  }


}
