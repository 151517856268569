import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, from, throwError } from 'rxjs';
import { catchError, filter, finalize, switchMap, take } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LoaderService } from '../services/loader.service';


@Injectable()
export class HttpTokenInterceptor implements HttpInterceptor {

  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(null);

  constructor(private router: Router, private authService: AuthService, private loaderService: LoaderService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // Check if user is authenticated and the request is not for refreshing the token
    if (this.authService.isAuthenticated() && !request.url.includes('auth/token')) {
      const token = this.authService.getToken();
      request = this.addTokenToRequest(request, token!);

      return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            return this.handle401Error(request, next);
          } else {
            return throwError(() => error);
          }
        })
      );
    }

    return next.handle(request);
  }

  private addTokenToRequest(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      }
    });
  }

  private handle401ErrorOld(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this.authService.refreshToken()).pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;

          // Extract the token from the response map
          const token = response.accessToken;

          if (token) {
            this.refreshTokenSubject.next(token);
            return next.handle(this.addTokenToRequest(request, token));
          } else {
            const errorResponse = new HttpErrorResponse({
              status: 401,
              statusText: 'Unauthorized',
              error: 'Votre token est expiré, veuillez vous reconnecter'
            });
            this.authService.logout();
            this.router.navigate(['auth/sign-in']);
            this.loaderService.setLoading(false);
            return throwError(() => errorResponse);
          }
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.authService.logout();
          this.router.navigate(['auth/sign-in']);
          this.loaderService.setLoading(false);
          return throwError(() => error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => next.handle(this.addTokenToRequest(request, token!)))
      );
    }
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return from(this.authService.refreshToken()).pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          const token = response.accessToken;
          if (token) {
            this.refreshTokenSubject.next(token);
            return next.handle(this.addTokenToRequest(request, token));
          } else {
            this.loaderService.setLoading(false); // <-- Stop loading before logout
            this.authService.logout();
            //this.router.navigate(['auth/sign-in']);
            return throwError(() => new HttpErrorResponse({
              status: 401,
              statusText: 'Unauthorized',
              error: 'Votre token est expiré, veuillez vous reconnecter'
            }));
          }
        }),
        catchError((error) => {
          this.isRefreshing = false;
          this.loaderService.setLoading(false); // <-- Stop loading before logout
          this.authService.logout();
          //this.router.navigate(['auth/sign-in']);
          return throwError(() => error);
        })
      );
    } else {
      return this.refreshTokenSubject.pipe(
        filter(token => token != null),
        take(1),
        switchMap(token => next.handle(this.addTokenToRequest(request, token!)))
      );
    }
  }


}